import Vue from 'vue'
import { pickBy } from 'lodash-es'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  adminLoadExternalCRM (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/load/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminCreateExternalCRM (context, params) {
    return Vue.http
      .post(apiRoot + '/admin/external-crm/create', params)
      .then(response => response.body)
      .catch(error => error.body)
  },

  adminUpdateExternalCRM (context, params) {
    return Vue.http
      .post(apiRoot + '/admin/external-crm/update', params)
      .then(response => response.body)
      .catch(error => error.body)
  },

  adminCreateExternalCRMExternalProducts (context, { externalCrmId, sourceName, sourceId, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-products`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    const data = { externalCrmId, sourceName, sourceId }

    return Vue.api.post(path, { params, data })
  },

  adminUpdateExternalCRMExternalProducts (context, { externalCrmId, id, sourceName, sourceId, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    const data = { externalCrmId, sourceName, sourceId }

    return Vue.api.patch(path, { params, data })
  },

  adminLoadExternalCRMExternalProductById (context, { externalCrmId, id, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.get(path, { params })
  },

  adminDeleteExternalCRMExternalProducts (context, { externalCrmId, id, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.delete(path, { params })
  },

  adminCreateExternalCRMExternalProductMappingExternalProduct (context, { externalCrmId, productId, subProductId, externalProductId, rank, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-product-mapping-external-products`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    const data = { productId, subProductId, externalProductId, rank }

    return Vue.api.post(path, { params, data })
  },

  adminDeleteExternalCRMExternalProductMappingExternalProduct (context, { externalCrmId, id, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-product-mapping-external-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.delete(path, { params })
  },

  adminListExternalCRMOpportunityFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/list-opportunity-fields/' + params.type, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListExternalCRMStatusFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/list-status-fields/' + params.type, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListExternalCRMPushCampaignsEnabled (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/list-push-campaigns-enabled', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListExternalCRMQuoteFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/list-quote-fields/' + params.type, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListExternalFieldMappings (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/list-field-mappings', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminValidateApiCredentials (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/admin/external-crm/validate-api-credentials', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminValidateExternalAccount (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/external-crm/validate-external-account', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  adminListExternalCRMExternalProducts (context, { externalCrmId, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-products`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.get(path, { params })
  },

  adminListExternalCRMInternalProducts (context, { externalCrmId, companyId, companyGroupId }) {
    const path = `/admin/external-crms/${externalCrmId}/internal-products`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.get(path, { params })
  },

  adminUpdateExternalCRMInternalProduct (context, { externalCrmId, companyId, companyGroupId, id, externalProductId }) {
    const path = `/admin/external-crms/${externalCrmId}/internal-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)
    const data = { externalProductId }

    return Vue.api.patch(path, { params, data })
  },

  adminFetchExternalCRMExternalProductMappingsExternalProduct (context, { externalCrmId, companyId, companyGroupId, productId }) {
    const path = `/admin/external-crms/${externalCrmId}/external-product-mapping-external-products`
    const params = pickBy({ companyId, companyGroupId, productId }, (v) => v !== undefined)

    return Vue.api.get(path, { params })
  },

  adminCreateExternalCRMExternalProductMappingsExternalProduct (context, { externalCrmId, companyId, companyGroupId, productId, externalProductSourceId, rank }) {
    const path = `/admin/external-crms/${externalCrmId}/external-product-mapping-external-products`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)
    const data = pickBy({ productId, externalProductSourceId, rank }, (v) => v !== undefined)

    return Vue.api.post(path, { params, data })
  },

  adminDeleteExternalCRMExternalProductMappingsExternalProduct (context, { externalCrmId, companyId, companyGroupId, id }) {
    const path = `/admin/external-crms/${externalCrmId}/external-product-mapping-external-products/${id}`
    const params = pickBy({ companyId, companyGroupId }, (v) => v !== undefined)

    return Vue.api.delete(path, { params })
  }
}

export default actions
